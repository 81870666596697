<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Rutas A Reconocer</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Rutas A Reconocer</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-12">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn bg-navy"
                        @click="getResults()"
                      >
                        <i class="fas fa-sync-alt fa-spin"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#modal-form-create-rutas-reconocer"
                        @click="[(ver_form_ruta_crear = true), create()]"
                        v-if="$store.getters.can('admin.reconocerRutas.create')"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-0">
                <table
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>
                        <label>Nombre</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nombre"
                          @keyup="getResults()"
                        />
                      </th>
                      <th>
                        <label>Tarifa</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.tarifa"
                          @keyup="getResults()"
                        />
                      </th>
                      <th>
                        <label>Estado</label>
                        <select
                          v-model="filtros.estado"
                          class="form-control form-control-sm"
                          @change="getResults()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th class="text-center p-3">
                        Acción
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="form">
                    <tr v-for="(rutaRe, indice) in rutas.data" :key="indice">
                      <td class="text-center">
                        {{ rutaRe.nombre }}
                      </td>
                      <td class="text-center">
                        {{ rutaRe.valorTarifa }}
                      </td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            rutaRe.estado == 1
                              ? 'badge-success'
                              : 'badge-warning'
                          "
                          >{{
                            rutaRe.estado == 1 ? "ACTIVO" : "INACTIVO"
                          }}</span
                        >
                      </td>
                      <td class="text-center">
                        <button
                          type="button"
                          class="btn btn-sm bg-navy"
                          data-toggle="modal"
                          data-target="#modal-form-rutas-reconocer"
                          @click="
                            [
                              (ver_form_ruta = true),
                              getFormRutaReconocer(rutaRe),
                            ]
                          "
                          v-if="$store.getters.can('admin.reconocerRutas.edit')"
                        >
                          <i class="fas fa-edit"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="rutas.total">
                  <p>
                    Mostrando del <b>{{ rutas.from }}</b> al
                    <b>{{ rutas.to }}</b> de un total:
                    <b>{{ rutas.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="rutas"
                  @pagination-change-page="getResults"
                  :limit="5"
                  class="float-right"
                >
                </pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <!-- Modal Crear -->
    <div
      v-if="ver_form_ruta_crear"
      class="modal fade"
      id="modal-form-create-rutas-reconocer"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">{{ modal.title }}</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="nombre">Nombre Ruta A Reconocer</label>
              <input
                type="text"
                maxlength="100"
                class="form-control form-control-sm"
                id="nombre"
                v-model="form.nombre"
                :class="$v.form.nombre.$invalid ? 'is-invalid' : 'is-valid'"
              />
            </div>
            <div class="form-group">
              <label for="codigo">Tarifa</label>
              <input
                type="text"
                class="form-control form-control-sm"
                id="tarifa"
                v-model="form.tarifa"
                :class="$v.form.tarifa.$invalid ? 'is-invalid' : 'is-valid'"
              />
            </div>
            <div class="form-group">
              <label>Estado</label>
              <select
                class="form-control form-control-sm"
                v-model="form.estado"
                :class="$v.form.estado.$invalid ? 'is-invalid' : 'is-valid'"
              >
                <option value="">Todos...</option>
                <option
                  v-for="estado in listasForms.estados"
                  :key="estado.numeracion"
                  :value="estado.numeracion"
                >
                  {{ estado.descripcion }}
                </option>
              </select>
            </div>
          </div>
          <div
            v-if="!$v.form.$invalid"
            class="modal-footer justify-content-between"
          >
            <button
              class="btn btn-sm btn-primary"
              v-show="!$v.form.$invalid"
              @click="saveRutaReconocer('Crear')"
              v-if="$store.getters.can('admin.reconocerRutas.create')"
            >
              <i class="fas fa-paper-plane"></i><br />Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Editar-->
    <div
      v-if="ver_form_ruta"
      class="modal fade"
      id="modal-form-rutas-reconocer"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">{{ modal.title }}</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label>Nombre</label>
              <input
                type="text"
                class="form-control form-control-sm"
                v-model="formReconocer.nombre"
                :class="
                  $v.formReconocer.nombre.$invalid ? 'is-invalid' : 'is-valid'
                "
              />
            </div>
            <div class="form-group">
              <label>Tarifa</label>
              <input
                type="text"
                class="form-control form-control-sm"
                v-model="formReconocer.tarifa"
                :class="
                  $v.formReconocer.tarifa.$invalid ? 'is-invalid' : 'is-valid'
                "
              />
            </div>
            <div class="form-group">
              <label>Estado</label>
              <select
                class="form-control form-control-sm"
                v-model="formReconocer.estado"
                :class="
                  $v.formReconocer.estado.$invalid ? 'is-invalid' : 'is-valid'
                "
              >
                <option value="">Todos...</option>
                <option
                  v-for="estado in listasForms.estados"
                  :key="estado.numeracion"
                  :value="estado.numeracion"
                >
                  {{ estado.descripcion }}
                </option>
              </select>
            </div>
          </div>
          <div
            v-if="!$v.formReconocer.$invalid"
            class="modal-footer justify-content-between"
          >
            <button
              type="button"
              class="btn btn-sm btn-primary"
              @click="saveRutaReconocer('Editar')"
              v-if="$store.getters.can('admin.reconocerRutas.save')"
            >
              <small>Guardar</small>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "../../../../components/Loading";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import { required } from "vuelidate/lib/validators";

export default {
  name: "RutaReconocerIndex",
  components: {
    Loading,
    pagination,
  },

  data() {
    return {
      filtros: {
        nombre: null,
        tarifa: null,
        estado: null,
      },
      cargando: false,
      form: {
        nombre: null,
        tarifa: null,
        estado: null,
      },
      formReconocer: {
        nombre: null,
        tarifa: null,
        estado: null,
      },
      metodo: "",
      ver_form_ruta: false,
      ver_form_ruta_crear: false,
      rutas: {},
      listasForms: {
        estados: [],
      },
      modal: {
        title: "",
        accion: "",
      },
      slct_nombre: "",
      slct_tarifa: "",
      slct_estado: [],
      id: null,
    };
  },

  validations() {
    return {
      formReconocer: {
        nombre: {
          required,
        },
        tarifa: {
          required,
        },
        estado: {
          required,
        },
      },
      form: {
        nombre: {
          required,
        },
        tarifa: {
          required,
        },
        estado: {
          required,
        },
      },
    };
  },

  methods: {
    async getResults(page = 1) {
      this.cargando = true;
      await axios
        .get("/api/admin/rutasReconocer?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          if (response.data.data && response.data.data.length > 0) {
            response.data.data.forEach((reconocer) => {
              let valorTarifa = reconocer.tarifa;
              reconocer.valorTarifa = this.formatCurrency(
                "es-CO",
                "COP",
                2,
                valorTarifa
              );
            });
          }
          this.rutas = response.data;
					this.cargando = false;
        });
      this.cargando = false;
    },

    saveRutaReconocer(accion) {
      this.cargando = true;
      axios({
        method: accion === "Crear" ? "POST" : "PUT",
        url:
          accion === "Crear"
            ? "/api/admin/rutasReconocer/create"
            : `/api/admin/rutasReconocer/${this.id}`,
        data: accion === "Crear" ? this.form : this.formReconocer,
      })
        .then((response) => {
          this.cargando = false;
          this.$refs.closeModal.click();
          this.$swal({
            icon: response.data.status == 1 ? "success" : "error",
            title: response.data.msg,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          this.limpiarForms();
          this.getResults();
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    getFormRutaReconocer(rutaRe) {
      this.modal.title = "Editar Ruta A Reconocer";
      this.formReconocer = {
        id: rutaRe.id,
        nombre: rutaRe.nombre,
        tarifa: rutaRe.tarifa,
        estado: rutaRe.estado,
      };
    },

    limpiarForms() {
      this.form = {};
      this.formReconocer = {};
      this.ver_form_ruta = false;
      this.ver_form_ruta_crear = false;
    },

    formatCurrency(locales, currency, fractionDigits, number) {
      var formato = new Intl.NumberFormat(locales, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: fractionDigits,
      }).format(number);

      return formato;
    },

    create() {
      this.modal.title = "Crear Ruta A Reconocer";
    },

    buscarEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    back() {
      return this.$router.replace("/Admin/RutasReconocer");
    },
  },

  beforeMount() {
    this.buscarEstados();
  },

  async mounted() {
    await this.getResults();
  },
};
</script>
